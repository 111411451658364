@import "../../styles/utils/mixins";
@import "../../styles/utils/variables";

.card {
  cursor: pointer;
  //min-width: 360px;
  color: $main-bg-color;
  gap: 16px;
  padding: 32px 24px 48px;
  background-color: $main-bg-block;
  box-shadow: 14px 15px $shadow-color;
  margin-right: 15px;

  transition: all 0.4s ease;

  @include onTablet {
    margin-right: 0;
  }

  @include onDesktop {
    min-width: 0;
    max-width: 360px;
    margin-right: 0;
  }

  &:hover {
    box-shadow: none;
  }
}

.card__content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
