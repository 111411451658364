@import "./styles/utils/variables";

html {
  font-family: 'Ubuntu', sans-serif;
  line-height: 24px;
  font-size: 16px;
  scroll-behavior: smooth;

  transition: all 0.4s ease;
}

h1 {
  font-size: 3.375rem;
  margin: 0;
}

h2 {
  font-size: 3rem;
  margin: 0;
}

h3 {
  font-size: 1.5rem;
  margin: 0;
  color: $main-bg-color;
  font-family: Ubuntu, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

h4, h5, h6 {
  font-size: 1.25rem;
  margin: 0;
}

p {
  margin: 0 0 1rem 0;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

a {
  text-decoration: none;

  transition: all 400ms ease;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background-color: $main-bg-color;
}

input {
  outline: none;
}

textarea {
  outline: none;
}
