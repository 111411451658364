@import "./styles/utils/variables";
@import "./styles/utils/mixins";

@import "./styles/header";
@import "./styles/about";


.logo {
  width: 34.76px;
  height: 28px;
}

.btn {
  cursor: pointer;
  border: none;
  padding: 16px 32px;

  font-family: 'Ubuntu', sans-serif;
  color: $main-bg-color;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  transition: all 400ms ease;

  &--dark_purple {
    background-color: $button-color;
  }
}

.btn:hover {
  background-color: $button-color-hover;
}

.btn--header {
  background-color: $button-color;
}

.btn--header:hover {
  background-color: $button-color-hover;
}

.btn--position {
  position: relative;
  display: flex;
  margin: 0 auto;
}





.img1 {
  z-index: 1;

  @include onDesktop {
    width: 380px;
    height: 420px;
  }
}

.img2 {
  box-shadow: -20px 20px $shadow-color;
  top: 151px;
  left: 170px;
  position: absolute;
  z-index: 2;

  @include onDesktop {
    width: 240px;
    height: 280px;
    top: 212px;
    left: 236px;
  }
}

.service {
  box-sizing: border-box;
  padding: 0 12px 64px;

  @include onTablet {
    padding: 0 75px 74px;
  }

  @include onDesktop {
    padding: 0 136px 80px;
  }

  &__logo {
    margin-bottom: 24px;

    @include onDesktop {
      margin-bottom: 48px;
    }
  }
}

.btn--color_about {
  background-color: $button-color;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;

  @include onTablet {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 44px;

    justify-items: stretch;
    align-items: stretch;
  }

  @include onDesktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    column-gap: 44px;
  }
}

.title {
  font-size: 54px;
  font-style: normal;
  line-height: 64px;

  &__h2 {
    font-size: 48px;
    font-style: normal;
    line-height: 56px;
  }

  &__normal {
    font-weight: 300;

    &--purple {
      color: $shadow-color;
    }
  }

  &__storng {
    font-weight: 700;
  }

  &__description {
    color: $main-bg-block;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &__space {
    margin-bottom: 24px;
  }
}

.service__card {
  display: flex;
  flex-direction: column;
  gap: 134px;

  @include onTablet {
    flex-direction: row;
    gap: 160px;
    align-items: center;

    &--reverse {
      flex-direction: row-reverse;
    }
  }

  &__bottom {

  }

  &__title {
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 56px;

    margin-bottom: 24px;

    &--purple {
      color: $shadow-color;
    }

    &--bold {
      font-weight: 700;
    }
  }

  &__description {
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    margin-bottom: 36px;
  }
}

.twice__image {
  position: relative;

  .img--big {
    width: 310px;
    height: 343px;
    object-fit: cover;

    @include onDesktop {
      width: 380px;
      height: 420px;
    }

    z-index: 1;
  }

  .img--small {
    position: absolute;
    z-index: 2;
    object-fit: cover;

    width: 196px;
    height: 228px;

    top: 151px;
    left: 170px;

    @include onDesktop {
      width: 240px;
      height: 280px;

      top: 212px;
      left: 236px;
    }

    box-shadow: -20px 20px $shadow-color;

    &--left {

      @include onDesktop {
        position: absolute;
        box-shadow: -20px 20px $shadow-color;
        top: 212px;
        left: -72px;
      }
    }
  }
}

.contact__page {
  padding-inline: 12px;
  padding-block: 64px;

  @include onTablet {
    padding-inline: 75px;
  }

  @include onDesktop {
    padding-inline: 136px;
  }
}

.contact__block {
  display: flex;
  flex-direction: column;
  gap: 80px;

  &__top {

    @include onTablet {
      position: relative;
      margin-top: 88px;
    }

  }

  &__image {
    display: flex;
    background-image: url(img/contacts_bg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 206px;
    z-index: 1;

    @include onTablet {
      position: relative;
      background-image: url(img/contacts_bg.svg);
      height: 521px;
      margin-left: 88px;
    }

    @include onDesktop {
      height: 521px;
      margin-top: 87px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 48px;
    padding: 24px;

    border: 1px solid var(--bg-15, rgba(173, 87, 197, 0.15));
    background: linear-gradient(0deg, rgba(173, 87, 197, 0.05) 0%, rgba(173, 87, 197, 0.05) 100%), #FFF;;

    @include onTablet {
      position: absolute;
      top: -87px;
      z-index: 2;
    }

    @include onDesktop {
      padding: 48px;
      width: 560px;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 36px;

    @include onTablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  h3 {
    color: $main-bg-block;
  }

  &__title {
    display: block;
    align-self: stretch;
    font-size: 48px;
    font-style: normal;
    font-weight: 300;
    line-height: 56px;

    margin-bottom: 36px;


    @include onTablet {
      margin: 0 auto;
      text-align: center;
      width: 566px;
    }

    &--stong {
      font-weight: 700;
    }
  }
}

.mini__block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &__link {
    cursor: pointer;
    color: #000;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.form {
  position: relative;
  display: grid;
  grid-row-gap: 32px;

  @include onTablet {
    padding: 36px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 92px 92px 235px;
    grid-column-gap: 24px;
  }

  &__lable {
    position: relative;
    display: grid;
    grid-row-gap: 8px;

    color: rgba(0, 0, 0, 0.50);
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &__field {
    height: 60px;
    align-items: center;
    gap: 10px;
    padding-left: 10px;

    align-self: stretch;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    border: 1px solid rgba(173, 87, 197, 0.15);
    background-color: rgba(173, 87, 197, 0.05);

    &__area {
      position: relative;
      padding: 10px;
      height: 180px;
      align-items: center;
      align-self: stretch;
      resize: none;
      overflow: hidden;
      font-size: 24px;
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.5);

      border: 1px solid rgba(173, 87, 197, 0.15);
      background-color: rgba(173, 87, 197, 0.05);

      margin-bottom: 36px;

      @include onDesktop {
        margin-bottom: 0;
      }
    }
  }
}

.grid-1-3 {

  @include onTablet {
    & {
      grid-column: span 2;
    }
  }
}

#map {
  border: 1px solid rgba(173, 87, 197, 0.15);

  width: 100%;
  height: 600px;
}

.leaflet-container {
  height: 100%;
}

.btn-space {
  margin-bottom: 36px;
}

.popup-window {
  background-color: #999;
}

.is-danger {
  transition: all 400ms ease;
  border: 1px solid $is-danger;
}

.help {
  position: absolute;
  top: 95px;

  color: $is-danger;
  font-size: 0.75rem;

  &--position-area {
    position: absolute;
    top: 231px;
  }
}

.btn--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: button-loading-spinner 1s linear infinite;
}

.btn-text--hidden {
  visibility: hidden;
  opacity: 0;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to  {
    transform: rotate(1turn);
  }
}




