@import "../../styles/utils/variables";
@import "../../styles/utils/mixins";

.footer {
  display: flex;
  flex-direction: column;
  background-color: $main-bg-block;
  padding: 48px 12px;

  @include onTablet {
    padding: 54px 75px 20px;
  }

  @include onDesktop {
    padding: 64px 136px 24px;
  }

  &__wrapper {
    margin-bottom: 32px;
    display: flex;
    gap: 32px;
    flex-direction: column;

    @include onTablet {
      display: flex;
      gap: 100px;
      flex-direction: row;
      margin-bottom: 24px;
    }

    @include onDesktop {
      gap: 140px;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    gap: 48px;

    @include onTablet {
      min-width: 360px;
    }
  }

  &__title {
    display: flex;
    gap: 16px;
  }

  &__bottom {
    width: 100%;
  }

  &__sign {
    color: $main-bg-color;
    font-size: 12px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }

  .contacts {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: $main-bg-color;
    padding: 0;
    margin: 0;
  }

  .link {
    color: $main-bg-color;
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
  }

  .link__service__footer {
    color: $main-bg-color;
  }

  &__service-list {
    display: grid;
    gap: 12px;

    @include onTablet {
      display: grid;
      column-gap: 120px;
      grid-template-columns: repeat(3, 1fr);
    }

    .list__link {
      cursor: pointer;
      color: $main-bg-color;
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &:hover {
        border-bottom: 1px solid $main-bg-color;
      }
    }
  }

  .list__title {
    margin-bottom: 12px;
  }
}
