@import "./utils/variables";
@import "./utils/mixins";

.header {
  color: $main-bg-color;
  background-color: $main-bg-block;
  box-sizing: border-box;
  background-size: cover;

  &__main-page {
    padding: 12px 12px 48px;

    @include onTablet {
      padding: 20px 75px 56px;
    }

    @include onDesktop {
      padding: 24px 136px 96px;
    }
  }

  &__text {
    margin-bottom: 36px;

    @include onTablet {
      margin-bottom: 48px;
      width: 494px;
    }
  }

  &__service {
    &-page {
      padding: 48px 24px 64px;

      @include onTablet {
        padding: 56px 24px 74px;
      }

      @include onDesktop {
        padding: 64px 24px 80px;
      }
    }

    &__text {
      color: $main-bg-color;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 36px;

      @include onTablet {
        width: 638px;
        margin-bottom: 48px;
      }
    }

  }

  &__bottom {
    padding-bottom: 29px;
    display: flex;
    flex-wrap: wrap;

    @include onTablet {
      flex-wrap: nowrap;
      padding-bottom: 0;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__welcome {
    margin-bottom: 24px;
  }



  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 48px;

    @include onTablet {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__content-image {
    box-shadow: 29px 29px $shadow-color;
    display: block;
    width: 337px;
    height: 386px;

    @include onDesktop {
      width: 420px;
      height: 480px;
    }
  }

  &__image {
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    background-image: url('../img/image__header-full.png');
  }
}
