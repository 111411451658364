.menu {
  background-color: #fff;
  position: fixed;
  top: 58px;
  left: 12px;
  right: 12px;

  animation: fade-in 500ms forwards;
}

.menu__content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px 48px;
  gap: 32px;

  font-size: 24px;
}

.menu__link {
  color: black;
  text-decoration: none;
}

.menu--open {
  @keyframes fade-in {
    100% {
      opacity: 1;
    }
  }
}

.menu--close {
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
  }
}
