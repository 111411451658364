@import "../../styles/utils/mixins";
@import "../../styles/utils/variables";

.main-menu {
  background-color: #051424;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include onTablet {
    padding: 16px 75px;
  }

  @include onDesktop {
    padding: 16px 136px;
  }

  &__logo {
    width: 34.76px;
    height: 28px;

    @include onTablet {
      width: 47px;
      height: 38px;
    }
  }
}

.icon {
  display: block;
  height: 28px;
  width: 28px;
  border: none;
  background-color: inherit;
  cursor: pointer;


  @include onTablet {
    display: none;
  }

  &__menu {
    background-image: url(./images/icon-burger.svg);
  }

  &__menu--close {
    background-image: url(./images/close-menu-burger.png);
  }
}

.nav {
  display: none;
  flex-direction: row;
  gap: 36px;


  @include onTablet {
    display: flex;
  }

  &__link {
    position: relative;
    color: $main-bg-color;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      display: block;
      height: 1px;
      width: 100%;
      background-color: #FFFFFF;

      transition: opacity 400ms;
      opacity: 0;
    }

    &:hover::after {
      opacity: 1;
    }
  }
}




